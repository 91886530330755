import(/* webpackMode: "eager", webpackExports: ["AttachBodyClass"] */ "/app/components/ContentRenderer/AttachBodyClass.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Attachment"] */ "/app/components/ContentRenderer/components/Attachment/Attachment.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Embed"] */ "/app/components/ContentRenderer/components/Embed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Gallery"] */ "/app/components/ContentRenderer/components/Gallery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/app/components/ContentRenderer/components/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoryBookmark","StoryBookmarkContextProvider"] */ "/app/components/ContentRenderer/components/StoryBookmark.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Variable","VariableContextProvider"] */ "/app/components/ContentRenderer/components/Variable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/app/components/ContentRenderer/components/Video.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/RichText/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/components/RichText/Quote.module.scss");
;
import(/* webpackMode: "eager" */ "/app/components/ContentRenderer/ContentRenderer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/components/PageTitle/PageTitle.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SocialShare"] */ "/app/components/SocialShare/SocialShare.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/Broadcast/BroadcastGallery.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/Broadcast/BroadcastNotifications.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/Broadcast/BroadcastPageType.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/Broadcast/BroadcastStory.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/Broadcast/BroadcastTranslations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DownloadLink"] */ "/app/modules/Gallery/DownloadLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/modules/Gallery/Gallery.module.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@prezly/analytics-nextjs/build/components/Tracking/Tracking.js");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/components/BookmarkCard/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["HtmlInjection"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/components/HtmlInjection.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Lightbox"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/components/Lightbox/Lightbox.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MultilineEllipsis"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/components/MultilineEllipsis/MultilineEllipsis.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["PinterestButton"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/components/PinterestButton/PinterestButton.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Rollover"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/components/Rollover/Rollover.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Iframe"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/elements/Embed/Iframe.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Gallery"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/elements/Gallery/Gallery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/elements/Image/Image.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/app/node_modules/@prezly/content-renderer-react-js/build/esm/elements/Video/Video.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@prezly/theme-kit-core/build/http/HttpClient.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@prezly/theme-kit-nextjs/build/adapters/routing/lib/RoutingAdapter.client.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@prezly/theme-kit-nextjs/build/adapters/theme-settings/client.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useDebounce"] */ "/app/node_modules/@prezly/theme-kit-react/build/hooks/useDebounce.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteLoading"] */ "/app/node_modules/@prezly/theme-kit-react/build/hooks/useInfiniteLoading.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["FormattedDate"] */ "/app/node_modules/@prezly/theme-kit-react/build/intl/FormattedDate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["FormattedMessage"] */ "/app/node_modules/@prezly/theme-kit-react/build/intl/FormattedMessage.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["FormattedTime"] */ "/app/node_modules/@prezly/theme-kit-react/build/intl/FormattedTime.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIntl","IntlProvider"] */ "/app/node_modules/@prezly/theme-kit-react/build/intl/IntlContext.mjs");
